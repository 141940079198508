.plist-item-container
{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 5px;
  gap: 15px;
}
.plist-head
{
  text-align: left;
    color: whitesmoke;
    font-size: 1.1rem;
    font-weight: 600;
padding-left: 30px;
margin-bottom: 20px;
margin-top: 20px;
    text-decoration: none;
    outline: none;
}
.plist-item
{
  height: 90px;
  width: 80%;
  border-radius: 10%;
  cursor: pointer;
  transition: all 0.3s ease;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 20px; /* Add some padding inside the box */
  justify-content: space-between;
  flex-direction: row;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.043);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.plist-item:hover
{
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1.1);
}
.plist-title
{
    text-align: left;
    color: whitesmoke;
    font-size: 1rem;
    font-weight: 600;

    text-decoration: none;
    outline: none;
}
.plist-description
{
    text-align: left;
    color: whitesmoke;
    font-size: 0.6rem;
    font-weight: 300;
    margin-bottom: 0;
    text-decoration: none;
    outline: none;
}
.ptext-content {
  margin: 10px;
    flex: 0 0 80%;
}
.pbuttons
{
    color: #ffffff;
    width: fit-content;
    padding: 7px 10px;
    flex: 1;
    border-radius: 100px;
    flex: 0 0 20%;
}