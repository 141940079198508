
.search
{
   display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.search-box
{
    top: 16%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    /* background: #2f3640; */
    height: 45px;
    background: rgba(255, 255, 255, 0.058);
     /* box-shadow: 0 0 10px rgba(136, 136, 136, 0.622); */
 
    backdrop-filter: blur(10px);
    padding: 20px;
    z-index: 1000;
    width: 80%;
    border-radius: 100px;
    display: flex ;
    justify-content: space-between;
    align-items: center;
    transition: 0.4s;
    flex-direction: row;
    color: whitesmoke;
}
.search-input
{
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: #d5d8dc;
    font-size: 0.8rem;
    transition: 0.4s;
    width: 100%;
}
.search-input::placeholder
{
    color: #d5d8dc;
    font-size: 0.8rem;
    padding: 20px;
    cursor: pointer;
}
.search-results-container {
    width: 100%;
    position: absolute;
    margin-top: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .search-results {
    padding: 10px;
    align-self: center;

    /* padding-top: 30%; */
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    text-align: left;
  }