.mini-player {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  margin-bottom: 55px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  padding: 10px;
  color: white;
  background: linear-gradient(50deg, rgb(31, 8, 29) 0%, rgb(0, 26, 91) 100%);
  backdrop-filter: blur(10px);
  /* box-shadow: 0 0 10px rgba(136, 136, 136, 0.622); */
  transition: 0.3s;
  }



.mini-image {
width: 40px;
height: 40px;
object-fit: cover;
border-radius: 15px;
}

.mini-content {
padding: 5px;
display: flex;
width: 100%;
flex-direction: column;
gap: 1px;
}
.list-title{
font-size: 1rem;
color: white;
font-weight: 600;
max-height: 3rem;

}
.list-description{
font-size: 0.7rem;
color: white;
font-weight: 300;
}
.list-btns{
  color: #ffffff;
width: fit-content;
/* padding: 7px 10px; */
flex: 1;
border-radius: 100px;
background: linear-gradient(50deg, rgba(146,25,133,1) 0%, rgba(25,59,146,1) 100%);    border: 0px solid transparent;
  transition: background-color 0.3s ease;
}

.mini-player h4,
.mini-player p {
  margin: 0;
}
.play-btn
{
  cursor: pointer;
  font-size: 15px;
  color: #fff;
  background: linear-gradient(50deg, rgba(146,25,133,1) 0%, rgba(25,59,146,1) 100%);      border-radius: 50%;
  padding: 12px 15px;
  transition: 0.5s;
}
.mini-player-content
{
  display: flex;
  gap: 10px;
  justify-content: space-between;

  width: 100%;
  align-items: center;

}