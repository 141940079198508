.main
{
  align-items: center;
  justify-items: center;
  /* display: flex; */
  /* flex-direction: column; */
}
* {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      /* font-family: 'Plus Jakarta Sans', sans-serif;   */
      font-family: 'Sora', sans-serif;  
      }
      .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh; /* Adjust this as needed */
      }
      
      .centered-loader {
        text-align: center;
      }

    body {
      display: inline;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      /* background-image: linear-gradient(to right top, #1d6796, #295785, #2f4873, #303960, #2e2b4e, #30274f, #33234f, #371e4f, #451f62, #541f75, #651c88, #78149b); */
      background-image: url("/bckg.svg");
      background-repeat: repeat;
      background-attachment:local;
    }

    .player-component {
      background: rgba(255, 255, 255, 0.058);
      border-radius: 30px;
      padding: 20px;
      min-width: 320px;
      text-align: center;
      /* max-width: 600px; */
      /* max-width: 600px;
       */
       max-width: 90%;
      margin: 30px;
      margin-top: 60px;
      /* margin-bottom: 60px; */
      position: relative;
      transition: 0.5s;
      backdrop-filter: blur(10px);
    }

    #toggleButton {
      background: white;
      border: none;
      padding: 5px 15px;
      border-radius: 30px;
      color: #651c88;
      position: absolute;
      font-size: 1.2rem;
      top: -20px;
      cursor: pointer;
      transform: translate(-70%); /* Center the button */
      transition: 0.5s;
    }

    .player-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    audio {
      width: 100%;
      margin-top: 20px;
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap:5px;
      padding: 20px 15px;
      color: #fff;
      font-size: 1rem;
    
    }
    .player-controls
    {
      display: flex;
      align-items: center;
      justify-content: center;
      gap:8px;
      width: 50%;
      color: #fff;
      font-size: 1.2rem;
      text-align: center;
    }
    #detail-button
    {
      margin: 0%;
    }
    .progress {
      position: relative;
    }
    
    .progress-circle {
      height: 10px;
      width: 10px;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
    }
.music-details
{
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
  position: absolute;
  top:40%;
  right: 20%;
  text-align: left;
  color: #fff;
  padding: 20px;
  font-size: 0.8rem;
  text-align: center;
  background: rgba(20, 5, 33, 0.516);
  backdrop-filter: blur(200px);
  filter:drop-shadow(0 0 10px rgba(0, 0, 0, 0.623));
  border-radius: 30px;
  z-index: 1000;
}
.music-details-p
{
  font-size: 0.6rem;
  font-weight: 300;
  color: #fff;

  text-align: left;
  line-height: 130%;
}
    .controls i {
      cursor: pointer;
      font-size: 15;
      color: #fff;
      border-radius: 50%;
      padding: 10px;
      transition: 0.5s;
}
    .controls i:hover {
      color: #dd00ff;
}
    .controls i {
      cursor: pointer;
      font-size: 15;
      color: #fff;
      border-radius: 50%;
      padding: 10px;
      transition: 0.5s;
    }

    #playPauseBtn {
      cursor: pointer;
      font-size: 24px;
      color: #fff;
      background: linear-gradient(50deg, rgba(146,25,133,1) 0%, rgba(25,59,146,1) 100%);      border-radius: 50%;
      padding: 18px 21px;
      transition: 0.5s;
    }

    .progress-bar {
      width: 90%;
      height: 5px;
      background-color: #ddd;
      border-radius: 5px;
      margin-top: 20px;
      position: relative;
    }

    .progress-bar div {
      height: 100%;
      border-radius: 3px;
      background-color: #dd00ff;
      position: absolute;
      top: 0;
      left: 0;
      transition: width 0.5s;
    }

    .timestamps {
      display: flex;
      width: 100%;
      justify-content: space-around;
      justify-items: flex-end;
      gap: 150px;

      color: #ffffffcb;
      margin-top: 5px;
      position: relative;
      transition: 0.5s;
    }

    .timestamps span {
      font-size: 0.7rem;
      font-weight: 300;
    }

    .player-container img {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 15px;
      margin-top: 40px;
    }

    .lyrics {
      text-align: left;
      transition: 0.5s;
      padding: 30px;
      height: 450px;
      overflow: scroll;
      scrollbar-color: #dd00ff27 #fff;
      scroll-behavior: smooth;
    }

    .hidden {
      display: none;
    }

    .player-text {
      margin-top: 20px;
    }

    #player-title {
      font-size: 1.2rem;
      font-weight: 700;
      color: #fff;
    }

    #player-description {
      font-size: 0.8rem;
      font-weight: 300;
      color: #fff;
      text-align: center;
      line-height: 180%;
    }

    .switch .player-container,
    .switch .lyrics {
      opacity: 0;
  display: none;
  transition: opcacity 0.5s;
      display: none;
    }

    .switch .lyrics {
      opacity: 1;
      display: block;
    }
    audio
    {
      display: inline;
      color: turquoise;
    }
    header {
      color: white;
      padding: 10px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo {
      text-align: center;
      flex-grow: 1;
    }
    .logo img
    {
      max-width: 100px;
      max-height: 100px;
    }

  

    .options {
      margin-left: 20px;
    }

    button {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 18px;
      margin: 0 10px;
    }

    .carousel-container
    {
        background: rgba(255, 255, 255, 0.058);
        border-radius: 30px;
        padding: 5px;
        margin-top: 30px;
        margin: 25px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: baseline;
        /* max-width: 600px; */
    }
    .carousel-title
    {
        /* margin-bottom: -5px; */
        /* padding-bottom: -5px; */
        padding: 15px 2px 0px 20px;
        font-size: 1rem;
        font-weight: 700;
        color: white;
    }
    .carousel {
        padding: 10px 0px 10px 20px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap:15px;
  scroll-behavior: smooth;
  scrollbar-width: none;
    }
  .card {
    flex: 0 0 auto;
  }
  .card-grid-title
  {
    padding: 20px;
    margin: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
  }
    .card {
      width: 200px;
      height: 120px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      border-radius: 15px;
      display: flex;
      /* justify-content: space-between; */
      transition: transform 0.3s ease;
    }
    .card:hover {
      transform: scale(1.05);
    }
    .background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    .gradient-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.484), transparent);
      z-index: 1;
    }
    .content {
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-around;
      /* left: 50%; */
      /* transform: translateX(-50%); */
      /* padding: 20px; */
      padding: 15px;
      padding-top: 500px;
      color: #fff;
      z-index: 2;
        display: flex;
    justify-content: space-between;
    align-items: center;
    }

    .content h5 {
      font-size: 0.8rem;
    }
    .content p {
      font-size: 0.5rem;
      font-weight: 300;
    }
    #play-icon {
        font-size: 0.8rem;
    }
    .content button
    {
       
      color: #ffffff;
    width: fit-content;
    padding: 7px 10px;
    flex: 1;
    border-radius: 100px;
    background: linear-gradient(50deg, rgba(146,25,133,1) 0%, rgba(25,59,146,1) 100%);    border: 0px solid transparent;
        transition: background-color 0.3s ease;
    }
    .content button:hover
    {
      background: white;
      color: blueviolet;
    }
    .card-grid {
      /* display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 10px;
      margin-top: 20px; */
      padding: 10px;
      display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-content: center;
  justify-content: center;
    }
    .list-container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 600px;
      gap: 10px;
      margin-top: 20px;
      margin:20px;
    }
    .list-item {
      display: flex;
      gap: 10px;
      padding: 10px;
      align-items: center;
      justify-content: space-around;
      border-radius: 15px;
      background: rgba(255, 255, 255, 0.053);
      backdrop-filter: blur(10px);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      transition: 0.3s;
    }
    .list-item:hover {
        scale: 1.05;
        } 
    .cover-image {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 15px;
    }
    .text-content {
      padding: 5px;
      display: flex;
      flex-direction: column;
      gap: 1px;
    }
    .list-title{
      font-size: 1rem;
      color: white;
      font-weight: 600;
      max-height: 3rem;
      overflow:scroll;
      scrollbar-width: none;
        text-overflow:ellipsis;
    }
    .list-description{
      font-size: 0.7rem;
      color: white;
      font-weight: 300;
    }
    .list-btns{
        color: #ffffff;
    width: fit-content;
    padding: 7px 10px;
    flex: 1;
    border-radius: 100px;
    background: linear-gradient(50deg, rgba(146,25,133,1) 0%, rgba(25,59,146,1) 100%);    border: 0px solid transparent;
        transition: background-color 0.3s ease;
     }
     .buttons-container{
        margin-right: 5px;
       display: flex;
       gap: 10px;
     }
        .list-btns:hover
        {
            background: white;
            color: blueviolet;
        }

        .l-card {
            flex: 0 0 auto;
          }
            .l-card {
              width: 140px;
              height: 90px;
              position: relative;
              overflow: hidden;
              cursor: pointer;
              border-radius: 15px;
              transition: transform 0.3s ease;
            }
            .l-card:hover {
              transform: scale(1.05);
            }
            .l-background-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
              position: absolute;
              top: 0;
              left: 0;
            }
            .l-gradient-layer {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);              z-index: 1;
            }
            .l-content {
              position: absolute;
              bottom: 0;
              /* left: 50%; */
              /* transform: translateX(-50%); */
              /* padding: 20px; */
              padding: 15px;
              padding-top: 500px;
              color: #fff;
              z-index: 2;
                display: flex;
            justify-content: space-around;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            }
        
            .l-content h5 {
              font-size: 0.6rem;
            }
            .l-content p {
              font-size: 0.5rem;
              font-weight: 300;
            }
            #l-play-icon {
                font-size: 0.8rem;
            }
            .l-content button
            {
               
              color: #ffffff;
            width: fit-content;
            font-size: 1rem;
            padding: 7px 10px;
            flex: 2;
            margin-left: px;
            border-radius: 100px;
            background: linear-gradient(50deg, rgba(146,25,133,1) 0%, rgba(25,59,146,1) 100%);    border: 0px solid transparent;
                transition: background-color 0.3s ease;
            }
            .l-content button:hover
            {
              background: white;
              color: blueviolet;
            }
            
            .l-card-grid {
                padding: 8px;
                margin-top: 2%;
                /* padding-top: 30%; */
                width: auto;
                height: fit-content;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 10px;
                text-align: left;
              /* display: grid;
              grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
              gap: 10px;
              margin-top: 20px; */
              /* padding: 8px; */
              /* margin-right: 0px; */
              /* display: flex;
          flex-wrap: wrap;
          gap: 10px;
          align-content: center;
          justify-content: center; */
            }
        .l-text-content {
          padding: 0px;
          display: flex;
          flex-direction: column;
          gap: 1px;
          width: 70px;
        }


        .musicPlayerContainer
        {
          width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-direction: column;

        }

        #minimizeButton {
          position: sticky;
          top: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.053);
          backdrop-filter: blur(10px);          border-radius: 50%;
          padding: 5px 10px;
          color: #fff;
          cursor: pointer;
        }